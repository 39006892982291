<template>
  <section class="AmbulanciasTrasladosForaneos empresas__inner container mt24 mb24">
    <ButtonBackHome
      to="/empresas/ambulancia"
      text="Regresar"
    />
    <header class="empresas__header">
      <h2 class="empresas_home__heading heading">Solicitud de ambulancia para traslados foráneos</h2>
      <div class="empresas_home__text">Te ayudamos durante el traslado de un paciente a otro lugar fuera de la ciudad de Chihuahua, Chihihuahua.<br><span style="background:#ecb731">El precio del servicio puede aplicar cargos adicionales en caso de que el destino sea fuera del estado de Chihuahua.</span></div>
    </header>
    <v-card
      flat
      :loading="cargando"
      :disabled="cargando"
    >
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="etf.paciente_etf"
            label="Nombre del paciente*"
            filled
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            :disabled="!etf.paciente_etf"
            v-model="etf.condicion_etf"
            label="Condición del paciente*"
            :items="selects.condicionPaciente"
            filled
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            :disabled="!etf.condicion_etf"
            v-model="etf.requerimientos_etf"
            label="Requerimientos del paciente"
            :items="selects.requerimientosPac"
            multiple
            filled
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="6"
            >
              <gMapsAutocompletePlaces
                :disabled="!etf.condicion_etf"
                v-model="eva.lugar_inicio_eva"
                @change="_handleLugar($event,'lugar_inicio_eva')"
                label="Lugar de origen*"
                hint="Es importante seleccione el lugar de la lista"
                persistentHint
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="6"
            >
              <v-text-field
                :disabled="!etf.condicion_etf"
                label="¿Algo que debamos saber del punto de partida?"
                v-model="eva.lugar_inicio_notas_eva"
                filled
                lugar_inicio_eva
                hint="Por ejemplo, entre que calles o alguna referencia para ubicar el domicilio"
                persistent-hint
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="6"
            >
              <gMapsAutocompletePlaces
                :disabled="!eva.lugar_inicio_eva"
                @change="_handleLugar($event,'lugar_termino_eva')"
                v-model="eva.lugar_termino_eva"
                label="Lugar de destino*"
                hint="Es importante seleccione el lugar de la lista"
                persistentHint
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="6"
            >
              <v-text-field
                :disabled="!eva.lugar_inicio_eva"
                label="¿Algo que debamos saber del punto de destino?"
                v-model="eva.lugar_termino_notas_eva"
                filled
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <DatePicker
                :disabled="!eva.lugar_termino_eva"
                label="Fecha del traslado*"
                v-model="eva.fecha_inicio_eva"
                sinceToday
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <TimePicker
                :disabled="!eva.lugar_termino_eva"
                v-model="eva.hora_inicio_eva"
                label="Hora del traslado*"
                :horaInicio="_hourToInt(configuracion.atencion_inicio_cotf)"
                :horaFin="_hourToInt(configuracion.atencion_fin_cotf)"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="4"
        >
          <v-card
            outlined
            class="mt12"
          >
            <v-list subheader>
              <v-subheader v-text="'RESUMEN'" />
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title v-text="`Distancia a recorrer: ${_kmFormat(this.etf.distancia_etf)} kms`" />
                </v-list-item-content>
              </v-list-item>
              <!-- <v-list-item>
                <v-list-item-content>
                  <v-list-item-title v-text="`Costo por kilómetro: ${$nF(this.configuracion.kilometro.costo_ser)}`" />
                </v-list-item-content>
              </v-list-item> -->
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title v-html="`<strong>Total:</strong> ${$nF(total)}*`" />
                  <v-list-item-subtitle class="nowrap mt4">*El precio del servicio puede aplicar cargos adicionales en caso de que el destino sea fuera del estado de Chihuahua.</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
      <small>No podrás reservar el evento si no estan llenos los campos indicados con *</small>
      <AmbulanciasReservarAcciones
        :tipo="tipo"
        :showActions="eva.hora_inicio_eva"
        :total="total"
        :eva="eva"
        :data="etf"
        @msg="$emit('msg',$event)"
        @httpError="$emit('httpError',$event)"
      />
    </v-card>
  </section>
</template>
<script>
import standard from "@/mixins/standard";
import ambulanciasConfig from "@/mixins/ambulanciasConfig.js";
import HeaderTitle from "@/components/header/HeaderTitle.vue";
export default {
  name: "AmbulanciasTrasladosForaneos",
  mixins: [standard, ambulanciasConfig],
  components: {
    HeaderTitle,
  },
  data() {
    return {
      tipo: "trasladosforaneos",
      etf: {
        tipo_etf: null,
        paciente_etf: null,
        condicion_etf: null,
        requerimientos_etf: null,
        distancia_etf: null,
        tiempo_etf: null,
      },
      configuracion: {
        anticipacion_horas_cotf: null,
        atencion_fin_cotf: null,
        atencion_inicio_cotf: null,
        cancelacion_falta_pago_cotf: null,
        kilometro: {
          costo_ser: null,
          desc_ser: null,
          estatus_ser: null,
          id_cta_ser: null,
          id_ser: null,
          id_tin_ser: null,
          tipo_ser: null,
        },
        kilometro_cotf: null,
      },
    };
  },
  methods: {
    _handleCheckPlaceDistance() {
      if (
        this.eva.lugar_inicio_eva &&
        "place_id" in this.eva.lugar_inicio_eva &&
        this.eva.lugar_termino_eva &&
        "place_id" in this.eva.lugar_termino_eva
      ) {
        this._getDistance(
          this.eva.lugar_inicio_eva.place_id,
          this.eva.lugar_termino_eva.place_id
        );
      }
    },
    _getDistance(origen, destino) {
      this.$http
        .post(
          "maps/distance",
          {
            origen: origen,
            destino: destino,
          },
          this.$headers
        )
        .then(
          (res) => {
            if (res.status === 200 && "distance" in res.body) {
              this.etf.distancia_etf = Math.round(
                parseFloat(res.body.distance).toFixed(2)
              );
              this.etf.tiempo_etf = res.body.duration;
            } else {
              this.$emit("httpError", res);
            }
          },
          (err) => {
            this.$emit("httpError", err);
          }
        );
    },
    _kmFormat(km) {
      var nf = new Intl.NumberFormat();
      return nf.format(km);
    },
  },
  computed: {
    total() {
      return (
        Math.ceil(
          (this.etf.distancia_etf * this.configuracion.kilometro.costo_ser) /
            100
        ) * 100
      );
    },
  },
  created() {
    this._getConfigAmbu(this.tipo);
  },
  watch: {
    eva: {
      deep: true,
      immediate: true,
      handler: "_handleCheckPlaceDistance",
    },
  },
};
</script>